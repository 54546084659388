import { Head, useForm } from '@inertiajs/react';
import { useEffect, FormEventHandler } from 'react';
import GuestLayout from '@/Layouts/GuestLayout';
import { Checkbox, CheckboxField } from '@/Components/Checkbox';
import { ErrorMessage, Field, Label } from '@/Components/Fieldset';
import { Input } from '@/Components/Input';
import { Link } from '@inertiajs/react';
import { Fieldset, FieldGroup } from '@/Components/Fieldset';
import PrimaryButton from '@/Components/PrimaryButton';
import MicrosoftIcon from '@/Icons/MicrosoftIcon';
import GoogleIcon from '@/Icons/GoogleIcon';

export default function Login({
    status,
    canResetPassword,
}: {
    status?: string;
    canResetPassword: boolean;
}) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const submit: FormEventHandler = (e) => {
        e.preventDefault();

        const popup = sessionStorage.getItem('popup');
        post(route('login'), {
            onSuccess: () => {
                if (popup && popup === 'unlogged') {
                    window.opener.postMessage({ refresh: true }, '*');
                    window.close();
                }
            },
        });
    };

    return (
        <GuestLayout>
            <Head title="Login to MailWizard | Access Your AI Email Assistant Now" />

            <div className="max-w-7xl mx-auto text-mw-white font-bold text-2xl sm:text-3xl">
                Welcome Back to MailWizard
            </div>

            <div className="mt-8 max-w-7xl mx-auto text-mw-white font-bold text">
                Login to Your Account
            </div>

            <div className="mt-8">
                <div className="mt-6 grid grid-cols-1 gap-4">
                    <a
                        href={route('google.redirect')}
                        className="flex w-full items-center justify-center gap-3 rounded-[30px] bg-mw-black1 px-4 py-2 text-sm font-semibold text-mw-white shadow-sm ring-1 ring-inset ring-mw-magenta hover:bg-mw-magenta hover:scale-105 focus-visible:ring-transparent transition ease-in-out duration-150"
                    >
                        <GoogleIcon />
                        <span className="text-xs font-semibold leading-6">
                            Sign in with Google
                        </span>
                    </a>

                    <a
                        href={route('microsoft.redirect')}
                        className="flex w-full items-center justify-center gap-3 rounded-[30px] bg-mw-black1 px-4 py-2 text-sm font-semibold text-mw-white shadow-sm ring-1 ring-inset ring-mw-magenta hover:bg-mw-magenta hover:scale-105 focus-visible:ring-transparent transition ease-in-out duration-150"
                    >
                        <MicrosoftIcon />
                        <span className="text-xs font-semibold leading-6">
                            Sign in with Microsoft
                        </span>
                    </a>
                </div>
                <div className="mt-10 relative">
                    <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                    >
                        <div className="w-full border-t border-text-mw-white" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                        <span className="bg-mw-black1 px-6 text-mw-white">
                            Or
                        </span>
                    </div>
                </div>
            </div>

            <div className="mt-8">
                {status && (
                    <div className="mb-4 font-medium text-sm text-mw-orange">
                        {status}
                    </div>
                )}
                <form onSubmit={submit} className="mt-10">
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Email</Label>
                                <Input
                                    required
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    autoComplete="username"
                                    onChange={(e) =>
                                        setData('email', e.target.value)
                                    }
                                    invalid={errors.email ? true : false}
                                />
                                {errors.email && (
                                    <ErrorMessage>{errors.email}</ErrorMessage>
                                )}
                            </Field>

                            <Field>
                                <Label>Password</Label>
                                <Input
                                    required
                                    id="password"
                                    type="password"
                                    name="password"
                                    value={data.password}
                                    autoComplete="new-password"
                                    onChange={(e) =>
                                        setData('password', e.target.value)
                                    }
                                    invalid={errors.password ? true : false}
                                />
                                {errors.password && (
                                    <ErrorMessage>
                                        {errors.password}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <div className="flex items-center justify-between">
                                <CheckboxField>
                                    <Checkbox
                                        onChange={(value) =>
                                            setData('remember', value)
                                        }
                                        checked={data.remember}
                                        name="remember"
                                    />
                                    <Label>Remember me</Label>
                                </CheckboxField>
                                <div className="text-sm leading-6">
                                    {canResetPassword && (
                                        <Link
                                            href={route('password.request')}
                                            className="underline text-sm text-mw-gray hover:text-mw-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        >
                                            Forgot your password?
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <PrimaryButton
                                type="submit"
                                className="w-full block items-center justify-center h-10"
                                disabled={processing}
                            >
                                Log in
                            </PrimaryButton>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </div>

            <p className="mt-8 text-center">
                <Link
                    href={route('register')}
                    className="underline text-sm text-mw-gray hover:text-mw-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                    Don't have an account? Sign up
                </Link>
            </p>
        </GuestLayout>
    );
}
